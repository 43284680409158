import React, { Component } from "react";
import { fetchAnunciosBy, fetchMotivos, getUserInfo, getAnuncianteInfo, fetchAnunciosRelacionados } from "./../../actions";
import { connect } from "react-redux";
import MapaAnuncio from "../../components/mapa-anuncio/mapa-anuncio";

// Components
import {
    AnuncioContainer,
    Container,
    CustomSubtitulo,
    Separator,
    Text,
    TextLi,
    ListUl,
    ContainerAnuncio,
    IconsInfoContainer,
    IconInfo,
    Icon,
    Info,
    InfoSubtitulo,
    EmailShared,
    FacebookShared,
    TwitterShared,
    LogoAnunciante,
    AnuncianteTitle,
    AnuncianteText,
    SimpleText
} from "./../../components/commons/theme-styled";

import TopBarInterno from "./../../components/topbar-interno/topbar-interno";
import ReportarAnuncio from "./../../components/reportar-anuncio/reportar";
import Contato from "./../../components/contato/contato";
import { anuncioMockup } from "./../../resources/mockups/anuncio-mockup";
import GPTComponent from "./../../components/gpt/GPT";
import Topbar from "./../../components/topbar/topbar";
import { Helmet } from "react-helmet";
import ImageGallery from "./../../components/image-gallery/imageGallery";
import Loading from "../../components/loading/loading";
import Slider from "react-slick";
import { ContainerSliderAnuncio } from "./AnuncioPage-styled";
import SuggestCard from '../../components/suggest-card/SuggestCard';

// Colors
import { Theme } from "../../components/commons/vitrine-colors";

// Icons
import AreaSVG from "./../../resources/svg/area.svg";
import QuartoSVG from "./../../resources/svg/quartos.svg";
import GaragemSVG from "./../../resources/svg/garagem.svg";
import SuiteSVG from "./../../resources/svg/suite.svg";
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import Favoritar from "./../../components/favoritar/favoritar";

// Images
import ClassiLogo from "./../../resources/svg/logo-classi.svg";

const vitrineType = process.env.VITRINE_TYPE.toLowerCase();

const PrevArrow = props => {

    const { currentSlide, slideCount, ...arrowProps } = props;
    return <ArrowBackIosOutlinedIcon  {...arrowProps} />

}

const NextArrow = props => {

    const { currentSlide, slideCount, ...arrowProps } = props;
    return <ArrowForwardIosOutlinedIcon {...arrowProps} />

}

const settingsCarrossel = {
    centerPadding: "60px",
    speed: 500,
    arrows: true,
    draggable: false,
    dots: true,
    responsive: [
        {
            breakpoint: 2500,
            settings: {
                slidesToScroll: 4,
                slidesToShow: 4,
                infinite: true,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToScroll: 2,
                slidesToShow: 2,
                infinite: true,
            },
        }
    ],
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
};

class Anuncio extends Component {

    constructor(props) {
        super(props);
    }

    state = {
        userEmail: "",
        isAuthenticated: false,
        isContactDialogOpen: false,
        useCurriculoPerfil: false,
        showLoading: true,
        anuncianteInfo: null,
    };

    async componentDidMount() {

        const { anuncioById, match } = this.props;
        const anuncioId = anuncioMockup(this.props.match.params.id);

        this.topContainer.scrollIntoView({ behavior: "instant" });


        await this.getContent();

    }

    async componentDidUpdate(prevProps) {

        const anuncioId = anuncioMockup(this.props.match.params.id);

        if (prevProps.location != this.props.location) {
            await this.getContent();
        }

        //Atualiza anuncio em caso de mudança de usuários.
        if (prevProps.authentication.userEmail !== this.props.authentication.userEmail) {
            this.props.fetchAnunciosBy(this.props.anuncioById.id);
            this.props.getUserInfo();
        }

        if (prevProps != this.props) {

            this.setState({
                showLoading: false
            })

        }

        this.setSlickParams();

    }

    async getContent() {

        const anuncioId = anuncioMockup(this.props.match.params.id);

        this.setState({ showLoading: true });

        await this.props.fetchAnunciosBy(anuncioId);
        this.props.fetchAnunciosRelacionados({ idAnuncio: anuncioId, qtdAnuncios: 8 });
        this.props.fetchMotivos();
        this.props.getUserInfo();

        this.setState({ showLoading: false });

        if (this.topContainer) {
            this.topContainer.scrollIntoView({ behavior: "smooth" });
        }

    }

    setSlickParams = () => {

        const { anunciosRelacionados } = this.props;

        if (anunciosRelacionados.length <= 2) {

            settingsCarrossel.responsive[0].settings.slidesToScroll = anunciosRelacionados.length;
            settingsCarrossel.responsive[0].settings.slidesToShow = anunciosRelacionados.length;
            settingsCarrossel.responsive[1].settings.slidesToScroll = anunciosRelacionados.length;
            settingsCarrossel.responsive[1].settings.slidesToShow = anunciosRelacionados.length;

        } else if (anunciosRelacionados.length <= 4) {

            settingsCarrossel.responsive[0].settings.slidesToScroll = anunciosRelacionados.length;
            settingsCarrossel.responsive[0].settings.slidesToShow = anunciosRelacionados.length;

        }

    }

    renderOferece = anuncio => {

        if (anuncio && anuncio.caracteristicas) {
            let items = anuncio.caracteristicas.map(item => {
                return `<li>${item.descricao}</li>`;
            });

            const list = `<ul>${items.join("")}</ul>`;

            return list;
        }

    };


    // TODO: Remover valor do titulo interno
    render() {
        const { anuncioById, authentication, motivosList, anunciosRelacionados } = this.props;
        const { showLoading } = this.state;

        return (
            <React.Fragment>

                <Helmet>

                    <title>{anuncioById.titulo}</title>

                    {/* Google */}
                    <meta property="title" content={anuncioById.titulo} />
                    <meta property="type" content="website" />
                    <meta property="url" content={`${process.env.BASE_URL}${this.props.location.pathname}`} />
                    <meta property="image" content={process.env.BASE_URL + ClassiLogo} />
                    <meta property="description" content={anuncioById.descricao ? anuncioById.descricao.replace(/(<([^>]+)>)/gi, "").substring(0, 153) : ""} />
                    <meta property="site_name" content="Classi" />

                    {/* Facebook Open Graph */}
                    <meta property="og:title" content={anuncioById.titulo} />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={`${process.env.BASE_URL}${this.props.location.pathname}`} />
                    <meta property="og:image" content={process.env.BASE_URL + ClassiLogo} />
                    <meta property="og:description" content={anuncioById.descricao ? anuncioById.descricao.replace(/(<([^>]+)>)/gi, "").substring(0, 153) : ""} />
                    <meta property="og:site_name" content="Classi" />
                    <meta property="fb:admins" content="177140826180797" />

                    {/* Twitter */}
                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:site" content="@classi_br" />
                    <meta name="twitter:title" content={anuncioById.titulo} />
                    <meta
                        name="twitter:description"
                        content={
                            anuncioById.descricao
                                ? anuncioById.descricao
                                    .replace(/(<([^>]+)>)/gi, "")
                                    .substring(0, 153)
                                : ""
                        }
                    />
                    <meta name="twitter:creator" content="@classi_br" />
                    <meta
                        name="twitter:image:src"
                        content={process.env.BASE_URL + ClassiLogo}
                    />

                    <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/gh/kenwheeler/slick@1.8.1/slick/slick.css" />
                    <link rel="stylesheet" type="text/css" href="//cdn.jsdelivr.net/gh/kenwheeler/slick@1.8.1/slick/slick-theme.css" />
                </Helmet>

                <Container direction="column" noPadding={true} ref={el => { this.topContainer = el; }}>

                    <Topbar withAnchor={false} backgroundColor={Theme[vitrineType].main} />

                    <TopBarInterno
                        titulo={anuncioById.titulo}
                        subtitulo={
                            `${anuncioById.localizacao && anuncioById.localizacao.bairro ? anuncioById.localizacao.bairro + ' - ' : ''}
                            ${anuncioById.localizacao && anuncioById.localizacao.cidade ? anuncioById.localizacao.cidade : ''}
                            `
                        }
                        idAnuncio={anuncioById.id}
                        valor={anuncioById.valor || anuncioById.ofertaSalarial}
                        codigo={anuncioById.codigo}
                        isFavorite={anuncioById.favorito}
                        authentication={authentication}
                        valorCombinar={anuncioById.valorCombinar} />

                    {/* Intervenção */}
                    <GPTComponent gtpId="intervencao" gpt="intervencao" />

                    <Container
                        direction="row"
                        align="flex-start"
                        justify="center"
                        paddingTopOnBreak="0" >

                        <AnuncioContainer>
                            {anuncioById &&
                                anuncioById.imagens &&
                                anuncioById.imagens.length > 0 && (
                                    <ImageGallery
                                        images={anuncioById.imagens}
                                    />
                                )}

                            {/* Ícones */}
                            <IconsInfoContainer>
                                {anuncioById.areaTotal > 0 && (
                                    <IconInfo hideInfo={false}>
                                        <Icon src={AreaSVG} />
                                        <Info>{anuncioById.areaTotal} m²</Info>
                                        <InfoSubtitulo>Área</InfoSubtitulo>
                                    </IconInfo>
                                )}

                                {anuncioById.qtdeQuartos > 0 && (
                                    <IconInfo hideInfo={false}>
                                        <Icon src={QuartoSVG} />
                                        <Info>{anuncioById.qtdeQuartos}</Info>
                                        <InfoSubtitulo>Quartos</InfoSubtitulo>
                                    </IconInfo>
                                )}

                                {anuncioById.qtdeSuites > 0 && (
                                    <IconInfo hideInfo={false}>
                                        <Icon src={SuiteSVG} />
                                        <Info>{anuncioById.qtdeSuites}</Info>
                                        <InfoSubtitulo>Suítes</InfoSubtitulo>
                                    </IconInfo>
                                )}

                                {anuncioById.qtdeVagas > 0 && (
                                    <IconInfo hideInfo={false}>
                                        <Icon src={GaragemSVG} />
                                        <Info>{anuncioById.qtdeVagas}</Info>
                                        <InfoSubtitulo>Garagem</InfoSubtitulo>
                                    </IconInfo>
                                )}
                            </IconsInfoContainer>

                            <Container
                                direction="row"
                                align="flex-start"
                                justify="center"
                                firstContainer={false}
                                noPadding={true}
                                onlyMobile={true}>

                                <ContainerAnuncio>

                                    <ContainerAnuncio
                                        width="100%"
                                        block={true}
                                        height="80px"
                                        top="12px"
                                        right="0">

                                        <Favoritar
                                            isFavorite={anuncioById.favorito}
                                            idAnuncio={anuncioById.id}
                                            authentication={authentication}
                                            isAnuncioPage={true} />

                                        <CustomSubtitulo align="flex-end" margin="8px -4px 0 0">Favoritar</CustomSubtitulo>

                                    </ContainerAnuncio>

                                </ContainerAnuncio>

                            </Container>

                            {/* Sobre o anunciante */}
                            {anuncioById.origem === 'PATIO_CARTEIRA' && anuncioById.anunciante &&
                                < React.Fragment >

                                    <br />

                                    <Container direction="row" align="center" justify="flex-start" noPadding={true} width="50%">

                                        <Container direction="column" align="center" justify="center" noPadding={true} width="35%">
                                            <LogoAnunciante src={anuncioById.anunciante.urlLogo} />
                                        </Container>

                                        <Container
                                            direction="column"
                                            align="flex-start"
                                            justify="flex-start"
                                            noPadding={true}
                                            width="65%">

                                            <AnuncianteTitle>{anuncioById.anunciante.nome}</AnuncianteTitle>
                                            <AnuncianteText>Empresa</AnuncianteText>
                                            {anuncioById.anunciante.cep && <AnuncianteText>CEP: {anuncioById.anunciante.cep}</AnuncianteText>}
                                            {anuncioById.anunciante.endereco
                                                && <AnuncianteText>{anuncioById.anunciante.bairro},
                                                {anuncioById.anunciante.endereco},
                                                {anuncioById.anunciante.numeroEndereco},
                                                {anuncioById.anunciante.cidade} - {anuncioById.anunciante.estado}</AnuncianteText>
                                            }

                                        </Container>

                                    </Container>

                                    <br />

                                </React.Fragment>
                            }

                            {/* Sobre o anúncio */}
                            <Separator showSeparator={anuncioById.descricao} texto="Sobre o anúncio" vitrinecolor={Theme[vitrineType].main} />
                            <Text dangerouslySetInnerHTML={{ __html: anuncioById.descricao }} />

                            {/* Detalhes */}
                            <Separator
                                showSeparator={
                                    anuncioById.caracteristicas &&
                                    anuncioById.caracteristicas.length > 0
                                }
                                texto="Detalhes"
                                vitrinecolor={Theme[vitrineType].main} />

                            <ListUl>
                                {anuncioById.caracteristicas &&
                                    anuncioById.caracteristicas.map(
                                        (item, index) => {
                                            return (
                                                <TextLi key={`descricao-${index}`} >
                                                    {item.descricao}
                                                </TextLi>
                                            );
                                        }
                                    )}
                            </ListUl>

                            {/* Mapa */}
                            <Separator
                                showSeparator={anuncioById.localizacao && anuncioById.localizacao.cidade}
                                texto="Localização"
                                vitrinecolor={Theme[vitrineType].main}
                            />

                            {anuncioById.localizacao && anuncioById.localizacao.cidade && anuncioById.localizacao.estado &&
                                <Container direction="row" justify="flex-start">
                                    <SimpleText color="#000000">
                                        <strong>Município: </strong> {anuncioById.localizacao.cidade} <strong>&nbsp;&nbsp; Estado: </strong> {anuncioById.localizacao.estado}
                                    </SimpleText>
                                </Container>
                            }

                            <GPTComponent gpt="megabox" />

                            {/* Sugestão de anúncio */}
                            <Separator showSeparator={true} texto="Anúncios similares" vitrinecolor={Theme[vitrineType].main} />

                            <Container width="auto" direction="row" firstContainer={false}>

                                <ContainerSliderAnuncio>

                                    <Slider className="slider" {...settingsCarrossel}>
                                        {anunciosRelacionados &&
                                            anunciosRelacionados.map(item => {
                                                return <SuggestCard key={`suggest-card-${item.id}`} anuncio={item} authentication={authentication} />
                                            })
                                        }
                                    </Slider>

                                </ContainerSliderAnuncio>

                            </Container>

                            {/* Viu algum problema? */}
                            <Separator showSeparator={true} texto="Viu algum problema?" vitrinecolor={Theme[vitrineType].main} />

                            <ReportarAnuncio
                                listaMotivos={motivosList}
                                idAnuncio={anuncioById.id} />

                            <Separator showSeparator={true} texto="Compartilhar nas redes sociais" vitrinecolor={Theme[vitrineType].main} />

                            <Container direction="row" justify="space-around">

                                <FacebookShared
                                    href={`https://www.facebook.com/sharer.php?u=${process
                                        .env.BASE_URL +
                                        this.props.location.pathname}`}
                                    target="_blank" />

                                <TwitterShared
                                    href={`http://twitter.com/intent/tweet?text=${anuncioById.titulo
                                        };?>&url=${process.env.BASE_URL +
                                        this.props.location.pathname}`}
                                    target="_blank" />

                                <EmailShared href={`mailto:?body=${process.env.BASE_URL + this.props.location.pathname}`} />

                            </Container>

                        </AnuncioContainer>

                        <Container
                            width="auto"
                            direction="unset"
                            align="unset"
                            justify="unset"
                            firstContainer={false}
                            noPadding={true}>

                            <Contato
                                anuncioId={anuncioById.id}
                                hideForm={anuncioById.origem === "CRAWLER"}
                                defaultMsg={`${process.env.BASE_URL}${this.props.location.pathname}`}
                                anunciante={anuncioById.anunciante}
                            />
                        </Container>

                    </Container>

                    {showLoading && <Loading />}

                </Container>

            </React.Fragment>

        );
    }
}

const mapStateToProps = store => ({
    anunciosRelacionados: store.anuncio.relacionados,
    anuncioById: store.search.anuncioById,
    authentication: store.authentication.authentication,
    motivosList: store.anuncio.motivosList
});

function loadData(store, match) {

    const anuncioId = anuncioMockup(match.params.id);

    return Promise.all([
        store.dispatch(fetchAnunciosBy(anuncioId)),
        store.dispatch(fetchAnunciosRelacionados({ idAnuncio: anuncioId, qtdAnuncios: 8 }))
    ]);

}

export default {
    loadData,
    component: connect(
        mapStateToProps,
        { fetchAnunciosBy, fetchMotivos, getUserInfo, fetchAnunciosRelacionados }
    )(Anuncio)
};
