import React, { Component } from "react";

//Components
import { Container } from "./../../components/commons/theme-styled";
import Topbar from "./../../components/topbar/topbar";

// //Styles
import { Logo, ErroContent, Titulo, Subtitulo } from "./Error404-styled";

//Imgs
import LogoClassi from "../../resources/svg/logo-classi.svg";

// Colors
import { Theme } from "../../components/commons/vitrine-colors";

const vitrineType = process.env.VITRINE_TYPE.toLowerCase();

const Error404 = ({ staticContext = {} }) => {
    staticContext.notFound = true;

    return (
        <Container
            backgroundColor="#400f50"
            firstContainer={true}
            direction="column"
            noPadding={true}
        >
            <Topbar
                withAnchor={false}
                backgroundColor={Theme[vitrineType].main}
            />
            <ErroContent>
                <Logo src={LogoClassi} />
                <Titulo>OPS!</Titulo>
                <Subtitulo>Página não encontrada</Subtitulo>
            </ErroContent>
        </Container>
    );
};

export default {
    component: Error404,
};
