import React, { Component } from 'react';
import ReactBnbGallery from 'react-bnb-gallery';

import { ImageGrid, ImagePrincipal, OtherImage } from './imageGallery-styled';
import { withRouter } from 'react-router-dom';

import { anuncioMockup } from '../../resources/mockups/anuncio-mockup';
import { buildUrl } from '../../components/image-render/image-render';

class ImageGallery extends Component {

    constructor() {
        super(...arguments);
        this.state = {
            galleryOpened: false,
            activePhotoIndex: 0
        };

        this.toggleGallery = this.toggleGallery.bind(this);
        this.renderOtherImgs = this.renderOtherImgs.bind(this);
        this.setImagesToLightBox = this.setImagesToLightBox.bind(this);
    }

    toggleGallery(index) {
        this.setState(prevState => ({
            galleryOpened: !prevState.galleryOpened,
            activePhotoIndex: index ? index : 0
        }));
    }

    setImagesToLightBox() {
        const list = this.props.images && this.props.images.map(img => {
            return { photo: buildUrl({src: img.imageUrl, width: 800, height: 600, resizeType:'fill'}), thumbnail: buildUrl({src: img.imageUrl, width: 100, height: 67}) }
        })

        return list;
    }

    renderOtherImgs() {

        if (this.props.images) {
            return <OtherImage src={this.props.images ? this.props.images[0].imageUrl : null} onClick={() => this.toggleGallery(0)} />            
        }

    }

    render() {
        let { images } = this.props;

        return (
            <div>
                <ImageGrid>
                    <ImagePrincipal src={this.props.images ? this.props.images[0].imageUrl : null} width="840" height="420" onClick={() => this.toggleGallery(0)} value={0} />

                    {images && images.length >= 2 &&
                        images.map((img, index) => {
                            if (index <= 3) {
                                return <OtherImage key={`otherImage-${index}`} src={this.props.images[index].imageUrl} width="200" height="150" onClick={() => this.toggleGallery(index)} />                                
                            }
                        })
                    }
                </ImageGrid>

                <ReactBnbGallery
                    phrases={{ showPhotoList: 'Mostrar lista', hidePhotoList: 'Esconder lista' }}
                    show={this.state.galleryOpened}
                    photos={this.setImagesToLightBox()}
                    onClose={this.toggleGallery}
                    activePhotoIndex={this.state.activePhotoIndex}
                />
            </div>
        );

    }

};

export default withRouter(ImageGallery);
