import React, { Component } from "react";
import { withRouter } from "react-router";

// Redux
import { connect } from "react-redux";

// Components
import {
    Container,
    CardContainer,
    ContainerTopo,
    TituloBusca,
    ContainerTitulo,
} from "./../../components/commons/theme-styled";

import CardAnuncio from "./../../components/card-anuncio/card-anuncio";
import Paginacao from "./../../components/paginacao/paginacao";
import Ordenacao from "./../../components/ordenacao/ordenacao";
import FiltroBusca from "./../../components/filtro/Filtros";
import Hidden from "@material-ui/core/Hidden";
import Topbar from "./../../components/topbar/topbar";
import Loading from "../../components/loading/loading";
import GPTComponent from "./../../components/gpt/GPT";
import { Helmet } from "react-helmet";

// Colors
import { Theme } from "./../../components/commons/vitrine-colors";

// Images
import ClassiLogo from "./../../resources/svg/logo-classi.svg";

// Actions
import { fetchCategories, fetchAnunciosList, getUser } from "./../../actions";

// Mockups
import { mockupFiltro } from "./../../resources/mockups/filtro-mockup";
import { UrlsClassiUtils } from "./../../resources/methods/UrlsClassiUtils";
import { StringUtils } from "./../../resources/methods/StringUtils";
import NoResults from "../../components/no-results/noResults";

const vitrineType = process.env.VITRINE_TYPE.toLowerCase();

class Busca extends Component {
    constructor(props) {
        super(props);

        let query = UrlsClassiUtils.getQuery(props.location.search);
        let estado = UrlsClassiUtils.getEstado(props.location.search);
        let cidade = UrlsClassiUtils.getCidade(props.location.search);

        let hasPresetFiltro = Boolean(query);

        this.state = {
            filtro: {
                ordenacao: "RELEVANCIA",
                pagina: 0,
                paginado: true,
                estado: estado ? estado.toUpperCase() : null,
                cidade: cidade ? StringUtils.improveString(cidade) : null,
                qtdRegistros: 12,
            },
            presetFiltro: {
                estado: estado,
                cidade: cidade,
                query: query,
            },
            showLoading: true,
            hasPresetFiltro: hasPresetFiltro,
        };
    }

    async componentDidMount() {
        try {
            const { anuncios, filtroSetup } = this.props.searchFilterData;
            const { filtro } = this.state;
            const getCategorias = await this.props.fetchCategories();
            let selectedSubsecao;

            if (this.props.categoryList) {
                let subsecao = UrlsClassiUtils.getSubsecao(
                    this.props.location.pathname
                );
                selectedSubsecao = this.props.categoryList.filter(
                    (item) =>
                        StringUtils.cleanString(item.descricao).toLowerCase() ==
                        subsecao
                );
                selectedSubsecao = selectedSubsecao
                    ? selectedSubsecao[0]
                    : null;

                filtro.categoria = selectedSubsecao
                    ? selectedSubsecao.valor
                    : null;
            }

            let query = UrlsClassiUtils.getQuery(this.props.location.search);
            filtro.query = query ? query : null;

            let hasPresetFiltro = Boolean(selectedSubsecao || query);

            const getAnuncios = await this.props.fetchAnunciosList(filtro);

            this.setState({
                presetFiltro: filtro,
                hasPresetFiltro: hasPresetFiltro,
            });
        } catch (error) {
            console.log(error);
        }

    }

    componentDidUpdate(prevProps, prevState) {
        const { searchFilterData, authentication } = this.props;

        //Atualiza lista de anúncios em caso de mudança de usuários.
        if (prevProps.authentication.userEmail != authentication.userEmail) {
            this.props.fetchAnunciosList(this.state.filtro);
        } else if (prevProps.searchFilterData != searchFilterData) {
            this.setState({
                categoria: searchFilterData.selectedCategoria,
                anuncios: searchFilterData.anuncios,
                cidades: searchFilterData.cidades,
                estados: searchFilterData.estados,
                ordenacao: searchFilterData.ordenacao,
                showLoading: false,
            });
        } else if (this.state.hasPresetFiltro) {
            this.setState({ hasPresetFiltro: false });
        }
    }

    // TODO: melhorar busca de filtro
    handleNewPlaybills = (key, value) => {
        const { filtro } = this.state;

        let newFilter = filtro;

        // Condição aplicada caso o componente seja duplo, por exemplo o filtro de Mínimo e Máximo
        if (Array.isArray(key)) {
            key.map((item, index) => {
                newFilter[item] = parseInt(value[index]);
            });

            this.setState({
                filtro: newFilter,
                showLoading: true,
                hasPresetFiltro: false,
            });
            this.props.fetchAnunciosList(newFilter);
        } else if (newFilter[key] !== value) {
            newFilter[key] = value || value === 0 ? value : null;

            switch (key) {
                case "estado":
                    //Caso especial, no qual o campo cidade deve ser limpado
                    newFilter["cidade"] = "";
                    break;
                case "categoria":
                    newFilter["tipoMarca"] = "";
                    newFilter["especieModelo"] = "";
                    break;
                case "tipoMarca":
                    newFilter["especieModelo"] = "";
                    break;
            }

            if (key !== "pagina") {
                newFilter["pagina"] = 0; //Toda alteração de filtro, menos de página, reseta a paginação
            }

            this.setNewUrl(newFilter);

            if (this.topContainer) {
                this.topContainer.scrollIntoView({ behavior: "smooth" });
            }

            this.setState({
                filtro: newFilter,
                showLoading: true,
                hasPresetFiltro: false,
            });

            this.props.fetchAnunciosList(newFilter);
        }

    };

    setNewUrl(filtro) {
        if (!this.state.hasPresetFiltro && this.props.searchFilterData) {
            let newfiltroCategoria = null;

            let newPathname = UrlsClassiUtils.makeNewPathname(
                this.props.location.pathname,
                filtro.estado,
                filtro.cidade,
                StringUtils.getDescricaoByValue(
                    filtro.categoria,
                    this.props.searchFilterData.categorias
                )
            );

            let newSearch = UrlsClassiUtils.makeNewSearch(
                this.props.location.search,
                filtro.query,
                filtro.pagina,
                false
            );

            window.history.pushState(
                null,
                null,
                window.location.origin +
                    "/" +
                    newPathname +
                    (newSearch ? "?" + newSearch : "")
            );
        }
    }

    geraTextoResultados() {
        let textoResultados = "";
        const { filtro } = this.state;

        if (
            filtro.categoria &&
            this.props.searchFilterData &&
            this.props.searchFilterData.categorias
        ) {
            let indexCategoria =
                this.props.searchFilterData.categorias.findIndex(function (o) {
                    return o.valor == filtro.categoria;
                });

            if (indexCategoria >= 0) {
                let categoria =
                    this.props.searchFilterData.categorias[indexCategoria];
                textoResultados = "Resultados para " + categoria.descricao;

                if (filtro.cidade) {
                    textoResultados += " em " + filtro.cidade;
                }
            }
        }

        return textoResultados;
    }

    render() {
        const { anuncios } = this.props.searchFilterData;
        const { searchFilterData, authentication } = this.props;
        const { showLoading, presetFiltro, hasPresetFiltro } = this.state;

        return (
            <React.Fragment>
                <Helmet>
                    <title>Classi Agro</title>

                    {/* Google  */}
                    <meta property="title" content="Classi Agro" />
                    <meta property="type" content="website" />
                    <meta property="url" content={process.env.BASE_URL} />
                    <meta
                        property="image"
                        content={process.env.BASE_URL + ClassiLogo}
                    />
                    <meta
                        property="description"
                        content="Os melhores agronegócios estão no Classificados mais tradicional de Goiás. Encontre fazendas, chácaras, animais, máquinas e muito mais."
                    />
                    <meta property="site_name" content="Classi" />

                    {/* Open Graph data  */}
                    <meta property="og:title" content="Classi Agro" />
                    <meta property="og:type" content="website" />
                    <meta property="og:url" content={process.env.BASE_URL} />
                    <meta
                        property="og:image"
                        content={process.env.BASE_URL + ClassiLogo}
                    />
                    <meta
                        property="og:description"
                        content="Os melhores agronegócios estão no Classificados mais tradicional de Goiás. Encontre fazendas, chácaras, animais, máquinas e muito mais."
                    />
                    <meta property="og:site_name" content="Classi" />
                    <meta property="fb:admins" content="177140826180797" />

                    {/* Twitter */}
                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:site" content="@classi_br" />
                    <meta name="twitter:title" content="Classi Agro" />
                    <meta
                        name="twitter:description"
                        content="Os melhores agronegócios estão no Classificados mais tradicional de Goiás. Encontre fazendas, chácaras, animais, máquinas e muito mais."
                    />
                    <meta name="twitter:creator" content="@classi_br" />
                    <meta
                        name="twitter:image:src"
                        content={process.env.BASE_URL + ClassiLogo}
                    />
                </Helmet>

                <Container
                    direction="column"
                    noPadding={true}
                    ref={(el) => {
                        this.topContainer = el;
                    }}
                >
                    <Container direction="column" noPadding={true}>
                        <Topbar
                            busca
                            withAnchor={false}
                            backgroundColor={Theme[vitrineType].main}
                            filters={FiltroBusca}
                            searchFilterData={searchFilterData}
                            handleFilter={this.handleNewPlaybills}
                            presetFiltro={hasPresetFiltro ? presetFiltro : null}
                        />
                    </Container>

                    {showLoading && <Loading />}

                    {/* Intervenção */}
                    <GPTComponent gtpId="intervencao" gpt="intervencao" />

                    <ContainerTopo
                        noPadding={true}
                        firstContainer={true}
                        direction="row"
                        align="initial"
                        justify="center"
                    >
                        <Container
                            noPadding={true}
                            direction="row"
                            align="initial"
                            justify="center"
                        >
                            <Container
                                noPadding={true}
                                direction="row"
                                align="initial"
                                justify="center"
                            >
                                {!showLoading && (
                                    <GPTComponent
                                        gptId="topo"
                                        key="publicidade-busca-top"
                                        gpt="small_maxiboard"
                                    />
                                )}
                            </Container>

                            <ContainerTitulo
                                maxWidth="1220px"
                                justify="flex-end"
                                padding="0 10px"
                                height="55px"
                            >
                                <ContainerTitulo
                                    maxWidth="900px"
                                    justify="space-between"
                                    paddingTop={"8px"}
                                >
                                    <TituloBusca>
                                        {this.geraTextoResultados()}
                                    </TituloBusca>

                                    {anuncios && anuncios.length > 0 && (
                                        <Ordenacao
                                            vitrinecolor={
                                                Theme[vitrineType].main
                                            }
                                            handleFilter={
                                                this.handleNewPlaybills
                                            }
                                            filterName="ordenacao"
                                            sortList={
                                                searchFilterData.ordenacao
                                            }
                                        />
                                    )}
                                </ContainerTitulo>
                            </ContainerTitulo>
                        </Container>

                        <Hidden xsDown>
                            <FiltroBusca
                                searchFilterData={searchFilterData}
                                handleFilter={this.handleNewPlaybills}
                                presetFiltro={
                                    hasPresetFiltro ? presetFiltro : null
                                }
                            />
                        </Hidden>

                        {anuncios && anuncios.length > 0 ? (
                            <CardContainer>
                                {anuncios &&
                                    anuncios.map((anuncio, index) => {
                                        return (
                                            <div key={index}>
                                                {index !== 0 && !(index % 3) ? (
                                                    <GPTComponent
                                                        gptId={`card-${index}`}
                                                        key={`publicidade-${index}`}
                                                        gpt="megabox"
                                                    />
                                                ) : null}

                                                <CardAnuncio
                                                    key={anuncio.id}
                                                    vitrine={
                                                        anuncio.secao.value
                                                    }
                                                    vitrineNome={
                                                        anuncio.secao.label
                                                    }
                                                    titulo={anuncio.titulo}
                                                    descricao={
                                                        anuncio.descricao
                                                    }
                                                    valor={
                                                        anuncio.ofertaSalarial
                                                    }
                                                    localizacao={
                                                        (anuncio.localizacao
                                                            .bairros
                                                            ? anuncio
                                                                  .localizacao
                                                                  .bairros
                                                            : anuncio
                                                                  .localizacao
                                                                  .cidade) +
                                                        " - " +
                                                        anuncio.localizacao
                                                            .estado
                                                    }
                                                    thumbnail={
                                                        anuncio.imagens &&
                                                        anuncio.imagens.length >
                                                            0
                                                            ? anuncio.imagens[0]
                                                                  .imageUrl
                                                            : null
                                                    }
                                                    url={anuncio.url}
                                                    isFavorite={
                                                        anuncio.favorito
                                                    }
                                                    idAnuncio={anuncio.id}
                                                    authentication={
                                                        authentication
                                                    }
                                                    prioridade={
                                                        anuncio.prioridade
                                                    }
                                                    categoria={
                                                        anuncio.categoriaDigital
                                                            .descricao
                                                    }
                                                    turno={
                                                        anuncio.turno &&
                                                        anuncio.turno.descricao
                                                    }
                                                    tipoVaga={
                                                        anuncio.tipoVaga &&
                                                        anuncio.tipoVaga
                                                            .descricao
                                                    }
                                                    escolaridade={
                                                        anuncio.escolaridadeMinima &&
                                                        anuncio
                                                            .escolaridadeMinima
                                                            .descricao
                                                    }
                                                    experiencia={
                                                        anuncio.experienciaMinima &&
                                                        anuncio
                                                            .experienciaMinima
                                                            .descricao
                                                    }
                                                    urlLogoFiliacao={
                                                        anuncio.urlLogoFiliacao
                                                    }
                                                    valorCombinar={
                                                        anuncio.valorCombinar
                                                    }
                                                    valor={anuncio.valor}
                                                    areaTotal={
                                                        anuncio.areaTotal
                                                    }
                                                    qtdeQuartos={
                                                        anuncio.qtdeQuartos
                                                    }
                                                    qtdeSuites={
                                                        anuncio.qtdeSuites
                                                    }
                                                    qtdeVagas={
                                                        anuncio.qtdeVagas
                                                    }
                                                    cardLogo={anuncio.marca}
                                                />
                                            </div>
                                        );
                                    })}
                            </CardContainer>
                        ) : (
                            <CardContainer>
                                <NoResults />
                            </CardContainer>
                        )}
                    </ContainerTopo>

                    <Container direction="row" align="initial" justify="center">
                        <Paginacao
                            filterName="pagina"
                            handleFilter={this.handleNewPlaybills}
                            qtdTotalRegistros={
                                searchFilterData.qtdTotalRegistros
                            }
                            vitrinecolor={Theme[vitrineType].main}
                            paginaAtual={searchFilterData.paginaAtual}
                            qntdPaginas={searchFilterData.qtdPaginas}
                        />
                    </Container>
                </Container>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (store) => ({
    categoryList: store.search.categoryList,
    searchFilterData: store.search.searchFilterData,
    authentication: store.authentication.authentication,
});

function loadData(store) {
    return store.dispatch(fetchAnunciosList(mockupFiltro));
}

export default {
    loadData,
    component: connect(mapStateToProps, {
        fetchCategories,
        fetchAnunciosList,
        getUser,
    })(withRouter(Busca)),
};
